.raspored-brands-div {
  display: flex;
  width: 1200px;
  overflow: auto;
  margin: 32px 0px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--blue);
}

.raspored-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  overflow: visible;
  height: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: var(--blue);
  padding: 6px 8px;
  border: 1px solid var(--blue);
  border-radius: 10px;
  cursor: pointer;
  margin-right: 16px;
}

.raspored-brand-active {
  background-color: var(--blue);
  color: white;
}

.raspored-artikli-div {
  display: flex;
  width: 1200px;
  flex-direction: column;
  align-items: start;
}

.raspored-artikal-div {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 8px 0px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--blue);
}

.raspored-artikal-drag-img {
  width: 24px;
  height: 24px;
}

.raspored-artikla-p {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  color: var(--blue);
  margin-left: 8px;
}

@media only screen and (max-width: 1200px) {
  .raspored-brands-div {
    display: flex;
    width: calc(100% - 16px);
    padding: 0px 8px;
    overflow: auto;
    margin: 32px 0px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--blue);
  }

  .raspored-artikli-div {
    display: flex;
    width: calc(100% - 16px);
    padding: 0px 8px;
    flex-direction: column;
    align-items: start;
  }
}
