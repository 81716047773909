.home-nav-div {
  display: flex;
  width: 1200px;
  justify-content: space-between;
  margin-top: 32px;
}

.home-btns-div {
  display: flex;
  align-items: center;
}

.home-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0px 16px;
  font-size: 18px;
  font-weight: 500;
  color: var(--blue);
  border: 1px solid var(--blue);
  border-radius: 10px;
  cursor: pointer;
  margin-right: 8px;
}

.home-btn-active {
  background-color: var(--blue);
  color: white;
}

.search-input-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(40px - 16px);
  padding: 8px 15px;
  width: calc(300px - 30px);
  border: 1px solid var(--blue);
  border-radius: 10px;
}

.search-input-img {
  width: 24px;
  height: 24px;
}

.search-input {
  display: flex;
  width: calc(300px - 69px);
  height: 100%;
  border: none;
  font-size: 16px;
  color: var(--darkGray);
  font-weight: 500;
}

.home-orders-div {
  display: flex;
  flex-direction: column;
  width: 1200px;
  height: auto;
  margin-top: 16px;
}

@media only screen and (max-width: 1200px) {
  .home-orders-div {
    width: 95vw;
  }

  .home-nav-div {
    flex-direction: column;
    width: 95vw;
    justify-content: start;
    margin-top: 16px;
  }

  .home-btns-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-btn {
    width: calc(100% - 32px);
    margin-right: 0px;
    margin-bottom: 8px;
  }

  .search-input-div {
    width: calc(100% - 30px);
  }

  .search-input {
    width: calc(100% - 16px);
  }

  .search-input-img {
    margin-right: 16px;
  }
}
