.order-bar-div {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 16px;
  width: calc(100% - 32px);
  background-color: #d9d9d910;
  border-radius: 10px;
  border: 1px solid var(--gray);
  margin-bottom: 16px;
}

.order-bar-info-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.order-bar-text-div {
  display: flex;
  align-items: center;
}

.order-number-h {
  font-size: 16px;
  font-weight: 600;
  color: var(--blue);
  margin-right: 16px;
}

.order-name-h {
  font-size: 18px;
  font-weight: 600;
  color: var(--blue);
}

.order-dot {
  display: flex;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  margin-left: 8px;
}

.dot-green {
  background-color: var(--green);
}

.dot-red {
  background-color: var(--red);
}

.order-date-p {
  font-size: 14px;
  font-weight: 500;
  color: var(--blue);
  margin-right: 16px;
}

.order-price-p {
  font-size: 20px;
  font-weight: 800;
  color: var(--blue);
}

.order-arrow-div {
  display: flex;
  padding: 7px;
  background-color: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 16px;
}

.order-arrow {
  width: 16px;
  height: 16px;
  transition: all 0.3s ease;
}

.arrow-rotated {
  transform: rotate(180deg);
}

.order-bar-content-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: start;
  transition: all 0.5s ease;
  height: 0px;
  margin-top: 0px;
  overflow: hidden;
}

.order-bar-content-div-open {
  height: auto;
  margin-top: 16px;
}

.order-product-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0px;
  border-top: 1px solid var(--blue);
}

.order-product-name {
  font-size: 14px;
  font-weight: 500;
  color: var(--blue);
  margin-right: 8px;
}

.order-product-amount {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: var(--darkGray);
}

.order-product-price {
  font-size: 16px;
  font-weight: 500;
  color: var(--blue);
}

.order-product-line {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: var(--blue);
}

.order-bar-note-div {
  display: flex;
  width: calc(100% - 18px);
  padding: 8px;
  border: 1px solid var(--darkGray);
  background-color: white;
  border-radius: 10px;
  margin-top: 16px;
  min-height: 60px;
  height: auto;
  font-size: 14px;
  font-weight: 500;
}

.order-bar-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--blue);
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  margin-top: 16px;
  padding: 10px 8px;
}

.order-total-amount-mobile {
  display: none;
}

@media only screen and (max-width: 750px) {
  .order-bar-div {
    padding: 12px;
    width: calc(100% - 24px);
  }

  .order-number-h {
    font-size: 10px;
    font-weight: 600;
    color: var(--blue);
    margin-right: 5px;
  }

  .order-name-h {
    font-size: 11px;
  }

  .order-dot {
    display: flex;
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }

  .order-date-p {
    font-size: 10px;
    margin-right: 5px;
  }

  .order-price-p {
    display: none;
  }

  .order-arrow-div {
    margin-left: 5px;
    padding: 6px;
  }

  .order-arrow {
    width: 12px;
    height: 12px;
  }

  .order-bar-content-div-open {
    height: auto;
    margin-top: 16px;
  }

  .order-product-name {
    font-size: 11px;
  }

  .order-product-amount {
    font-size: 10px;
  }

  .order-product-price {
    font-size: 12px;
  }

  .order-bar-note-div {
    display: flex;
    width: calc(100% - 12px);
    padding: 5px;
    font-size: 12px;
  }

  .order-bar-btn {
    width: calc(100% - 20px);
    margin-top: 8px;
  }

  .order-total-amount-mobile {
    display: block;
    width: 100%;
    text-align: end;
  }
}
