.komercijalista-pfp {
  width: 50px;
  height: 50px;
  margin-right: 16px;
}

.komercijalista-pfp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 16px;
  background-color: var(--blue);
  color: white;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
}

@media only screen and (max-width: 750px) {
  .komercijalista-pfp {
    width: 35px;
    height: 35px;
    margin-right: 5px;
  }
}
