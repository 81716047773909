.page-content {
  display: flex;
  flex-direction: column;
  width: 1200px;
}

.artikli-page-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
}

.artikli-page-h {
  font-size: 30px;
  color: var(--blue);
  font-weight: 500;
}

.blue-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  background-color: var(--blue);
  color: white;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
}

.artikli-brands-div {
  display: flex;
  width: 100%;
  margin-top: 32px;
  overflow: auto;
}

.brand-div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 380px;
  max-width: 380px;
  height: 146px;
  border-radius: 20px;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.25);
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 7px;
  cursor: pointer;
}

.delete-brand-icon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.brand-div-active {
  box-shadow: none;
  border: 3px solid var(--blue);
}

.brand-img {
  max-width: 280px;
  max-height: 100px;
  object-fit: contain;
}

.blue-line {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: var(--blue);
  margin: 16px 0px;
}

.artikli-div {
  display: flex;
  width: 100%;
  margin-top: 32px;
  flex-wrap: wrap;
}

/* MODAL STYLES */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background-color: white;
  padding: 32px;
  border-radius: 10px;
  width: calc(482px - 64px);
  max-width: calc(95vw - 64px);
  height: auto;
}

.image-input-helper {
  display: flex;
  width: 100%;
  justify-content: center;
}

.modal-h {
  font-size: 20px;
  color: var(--black);
  text-align: center;
  font-weight: 600;
  margin-bottom: 32px;
}

.modal-image-input-div {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 208px;
  border: 1px dashed var(--darkGray);
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 16px;
  overflow: hidden;
}

.artikal-image-input {
  width: 160px;
  height: 160px;
}

.modal-image-img {
  width: 24px;
  height: 24px;
  margin-bottom: 8px;
}

.modal-image-p {
  font-size: 14px;
  font-weight: 400;
  color: var(--darkGray);
}

.modal-image-input-div input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.modal-input {
  display: flex;
  padding: 8px 16px;
  width: calc(100% - 32px);
  font-size: 16px;
  color: var(--blue);
  font-weight: 500;
  border: 1px solid var(--darkGray);
  border-radius: 5px;
  min-height: calc(40px - 16px);
  margin-bottom: 16px;
}

.modal-select-input {
  padding: 8px 16px;
  width: calc(100% - 32px);
  font-size: 16px;
  color: var(--darkGray);
  border: 1px solid var(--darkGray);
  margin-bottom: 16px;
  min-height: calc(40px - 16px);
}

.modal-btns-div {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.modal-secondary-btn {
  display: flex;
  padding: 8px 32px;
  font-size: 16px;
  color: var(--blue);
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid var(--blue);
  cursor: pointer;
}

.delete-icon-div {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

.delete-icon {
  width: 24px;
  height: 24px;
}

.modal-primary-btn {
  display: flex;
  padding: 8px 32px;
  font-size: 16px;
  color: white;
  font-weight: 500;
  border-radius: 10px;
  background-color: var(--blue);
  cursor: pointer;
  margin-left: 8px;
}

.modal-small-input-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modal-small-input {
  display: flex;
  padding: 8px 16px;
  width: calc(48% - 32px);
  font-size: 16px;
  color: var(--blue);
  font-weight: 500;
  border: 1px solid var(--darkGray);
  border-radius: 5px;
  min-height: calc(40px - 16px);
  margin-bottom: 16px;
}

.modal-textarea {
  font-family: "Poppins";
  display: flex;
  padding: 8px 16px;
  min-width: calc(100% - 32px);
  max-width: calc(100% - 32px);
  max-height: 86px;
  min-height: 86px;
  font-size: 16px;
  color: var(--blue);
  font-weight: 400;
  border: 1px solid var(--darkGray);
  border-radius: 5px;
  min-height: calc(40px - 16px);
  margin-bottom: 16px;
}

.display-flex {
  display: flex;
  align-items: center;
}

.modal-image-live {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal-product-image-live {
  object-fit: cover;
}

.modal-error-p {
  color: var(--red);
  font-size: 12px;
  font-weight: 400;
}

.all-brand-h {
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  color: var(--blue);
}

@media only screen and (max-width: 1200px) {
  .page-content {
    width: 95vw;
  }

  .artikli-page-nav {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    margin-top: 16px;
  }

  .blue-btn {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 756px) {
  .brand-div {
    min-width: 253.33px;
    max-width: 253.33px;
    height: 97.33px;
    border-radius: 10px;
  }

  .brand-img {
    max-width: 189.08px;
    max-height: 66.67px;
  }

  .modal-content {
    padding: 16px;
    border-radius: 10px;
    width: calc(482px - 32px);
    max-width: calc(95vw - 32px);
  }
}
