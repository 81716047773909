.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-header-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  padding: 8px 0px;
  background-color: var(--blue);
}

.login-header-logo {
  width: 91px;
  height: 58px;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 520px;
  height: auto;
  margin-top: 64px;
}

.login-form-h {
  font-size: 35px;
  font-weight: 700;
  color: var(--blue);
}

.login-form-p {
  font-size: 18px;
  font-weight: 400;
  color: var(--darkGray);
  margin-bottom: 25px;
  text-align: center;
}

.login-form-label {
  display: flex;
  width: 100%;
  text-align: start;
  font-size: 18px;
  font-weight: 500;
  color: var(--black);
  margin-bottom: 5px;
}

.login-form-input {
  display: flex;
  padding: 10px;
  width: calc(100% - 20px);
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 10px;
  border: 1px solid var(--blue);
}

.login-form-btn {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 10px 0px;
  color: white;
  cursor: pointer;
  background-color: var(--blue);
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
}

.login-form-error-p {
  display: flex;
  width: 100%;
  text-align: start;
  color: var(--red);
  font-size: 14px;
  font-weight: 400;
}

@media only screen and (max-width: 520px) {
  .login-form {
    width: 95vw;
  }
}
