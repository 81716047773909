.header-container {
  display: flex;
  width: calc(100vw - 64px);
  align-items: center;
  justify-content: space-between;
  padding: 8px 32px;
  background-color: var(--blue);
}

.header-img {
  width: 91px;
  height: 58px;
}

.header-links-div {
  display: flex;
}

.header-link {
  display: flex;
  align-items: center;
  padding: 8px 32px;
  font-size: 14;
  font-style: normal;
  font-weight: 500;
  color: white;
  cursor: pointer;
  border-radius: 10px;
}

.header-link:hover {
  text-decoration: underline;
}

.header-link-active {
  background-color: white;
  color: var(--blue);
}

.link {
  text-decoration: none;
}

.hambuer-icon-div {
  display: none;
}

.header-container-mobile {
  display: none;
}

.header-link-mobile {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .header-container {
    display: flex;
    width: calc(100vw - 32px);
    padding: 8px 16px;
  }

  .header-links-div {
    display: none;
  }

  .hambuer-icon-div {
    display: flex;
  }

  .header-container-mobile-close {
    height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .header-container-mobile-open {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: calc(100vw - 32px);
    height: auto;
    padding: 8px 16px;
    background-color: var(--blue);
  }

  .header-link-mobile {
    display: flex;
    width: 100%;
    padding: 8px;
    color: white;
  }

  .header-link-mobile-active {
    display: flex;
    width: calc(100% - 16px);
    padding: 8px;
    color: var(--blue);
    background-color: white;
    border-radius: 10px;
  }

  .link {
    width: 100%;
  }
}
